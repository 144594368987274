<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <v-icon
        v-bind:class="mobile ? 'iconBackMobile' : 'iconBack'"
        v-bind:size="mobile ? 40 : 50"
        color="#1579A7"
        @click="$router.push({name: 'PhysicalActivities'}).then(() => {$vuetify.goTo(0)})"
    >
      mdi-arrow-left
    </v-icon>
    <div v-bind:class="mobile ? 'contentMobile1' : 'content'">
      <v-row justify="center" no-gutters>
        <v-col v-bind:cols="mobile ? 10 : 6">
          <div class="text" v-html="$t('text1')"></div>
          <div class="textBlue" v-html="$t('text2')"></div>
        </v-col>
        <v-col cols="6" v-if="!mobile">
          <div class="imgFirstSection">
            <v-img
                :src="require('@/assets/picto/pictoPeople7.svg')"
                contain
                height="450"
            />
          </div>
        </v-col>
      </v-row>
    </div>
    <v-row justify="center" v-bind:class="mobile ? 'secondSectionMobile' : 'secondSection'" no-gutters>
      <v-col cols="5" style="text-align: right" v-if="!mobile">
        <h3 class="subtitle" v-html="$t('subtitle1')"></h3>
        <div class="text" v-html="$t('text3')"></div>
        <h3 class="subtitle" style="padding-top:465px;" v-html="$t('subtitle3')"></h3>
        <div class="text" v-html="$t('text5')"></div>
      </v-col>
      <v-col v-bind:cols="mobile ? 1 : 2">
        <v-img
            :src="require('@/assets/picto/separator11.svg')"
            contain
            v-bind:height="mobile ? 1000 : 900"
        />
      </v-col>
      <v-col v-bind:cols="mobile ? 9 : 5">
        <h3 class="subtitle" v-html="$t('subtitle1')" v-if="mobile"></h3>
        <div class="text" v-html="$t('text3')" v-if="mobile"></div>
        <h3 class="subtitle" v-bind:style="mobile ? 'padding-top:120px;' : 'padding-top:300px;'" v-html="$t('subtitle2')"></h3>
        <div class="text" v-html="$t('text4')"></div>
        <h3 class="subtitle" v-bind:style="mobile ? 'padding-top:80px;' : 'padding-top:120px;'" v-html="$t('subtitle3')" v-if="mobile"></h3>
        <div class="text" v-html="$t('text5')" v-if="mobile"></div>
        <h3 class="subtitle" style="padding-top: 120px;" v-html="$t('subtitle4')" v-bind:style="mobile ? 'padding-top:80px;' : 'padding-top:350px;'"></h3>
        <div class="text" v-html="$t('text6')" ></div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'IndividualSupportComp',
    props: ['mobile'],
    data: () => ({
    }),
  }
</script>

<i18n>
{
  "en": {
    "mainTitle": "Community",
    "textBtn": "Join our health sport network"
  },
  "fr": {
    "mainTitle": "Notre prise en charge individuelle sur mesure",
    "text1": "Le plus dur pour se mettre au sport, c’est de trouver la bonne motivation, celle qui va faire qu’on ne lâchera pas après la première séance. D’autant plus pour les personnes sédentaire éloignés de l’activité physique. <br> <br> Trouver un coach sportif individuel est parfois la solution idéal pour trouver la motivation de se remettre en forme.  <br><br> Nos prise en charge s’adapte à tous les niveaux de forme, à toutes les envies et à toutes les motivations.",
    "text2": "<br> <strong> Après un premier bilan et en fonction des objectifs ciblés, un programme sera conçu sur mesure. </strong>",
    "text3": "<br>En amont d’une prise en charge nous effectuons un diagnostic complet et personnalisé. <br><br> On fixe des objectifs à atteindre et on réalise des évaluations à partir d’outils scientifiques permettant d’avoir une vision précise des composantes à améliorer.",
    "text4": "<br> A partir du bilan personnalisé, un programme sera créé sur mesure. En fonction de vos envie et objectifs, nous pourrons vous proposer et faire évoluer le type d’activité d’une semaine sur l’autre : Pilates, Gym douce, Yoga, équilibre, aquagym, marche nordique...",
    "text5": "<br>Pour compléter votre prise en charge, nous pourrons vous mettre en lien avec des professionnels de la santé et du bien-être : kiné, diététicien, ergothérapeute, naturopathe, masseur…",
    "text6": "<br>Pour une prise en charge optimale, nous mettons à disposition des outils adaptés (livret et vidéo) afin que vous puissiez réaliser des petites séances en dehors de nos interventions.",
    "subtitle1": "Bilan personnalisé",
    "subtitle2": "Une prise en charge adaptée",
    "subtitle3": "Une prise en charge globale",
    "subtitle4": "Un accompagnement quotidient",
    "textBtn": "Rejoindre notre réseau sport santé"
  }
}
</i18n>

<style scoped>

@media screen and (max-width: 1500px) {
  .btnContent {
    margin-top: 40px;
    font-size: 18px;
    width: 470px;
    padding-left: 20px;
    padding-right: 20px;
    text-transform: none !important;
  }

  .content {
    font-size: 14px;
    padding-right: 200px;
    padding-left: 200px;
  }
  .secondSection {
    font-size: 14px;
    padding: 60px 200px;
    background-color: #D5EEF4;
  }

}
@media screen and (min-width: 1500px) {
  .btnContent {
    font-size: 18px;
    width: 470px;
    padding-left: 20px;
    padding-right: 20px;
    text-transform: none !important;
  }

  .content {
    padding-bottom: 40px;
    padding-right: 200px;
    padding-left: 200px;
  }
  .secondSection {
    padding: 60px 200px;
    background-color: #D5EEF4;
  }
}

.iconBack {
  position: absolute;
  top: 30px;
  left: 80px;
}


.iconBackMobile {
  position: absolute;
  top: 55px;
  left: 20px;
}

.btnContentMobile {
  font-size: 15px;
  width: 350px;
  padding-left: 20px;
  padding-right: 20px;
  text-transform: none !important;
}

.contentMobile1 {
  font-size: 14px;
}

.secondSectionMobile {
  font-size: 14px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.text {
  color: #808080;
}

.subtitle {
  color: #57939E;
}

.textBlue {
  color: #1579A7;
}

.imgFirstSection {
  margin-right: 100px;
}

.btnContact {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 40px;
}

</style>
